<template>
  <dv-border-box-10 :color="['rgba(46, 96, 153, 1)', 'rgba(124, 231, 253, 1)']">
    <div class="scroll-board-wrap">
      <div class="title">数据企业名单</div>
      <div class="scoll-box">
        <dv-scroll-board :config="config"/>
      </div>
    </div>
  </dv-border-box-10>
</template>

<script>
export default {
  name: 'rightBottom',
  props: {
    originData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    config: function() {
      if (this.originData && this.originData.length > 0) {
        let companyList = this.originData.map(item => [item.company])
        return {
          data: companyList,
          headerBGC: 'rgba(3, 169, 244, 0.6)',
          oddRowBGC: 'rgba(46, 96, 153, 0.6)',
          evenRowBGC: 'rgba(3, 169, 244, 0.6)',
          align: ['center'],
          rowNum: 6
        }
      } else {
        return {}
      }
      
    },
  },
  data() {
    return {
      // config: {
        // header: ['数据企业名单'],
        // data: [
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],
        //   ['杭州亿尚智能科技有限公司'],

        // ],
        // headerBGC: 'rgba(3, 169, 244, 0.2)',
        // oddRowBGC: 'rgba(46, 96, 153, 0.1)',
        // evenRowBGC: 'rgba(3, 169, 244, 0.1)',
        // align: ['center'],
        // rowNum: 6,
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-board-wrap {
  padding: 20px 40px 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .title {
    text-align: center;
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 16px;
    font-family: '黑体';
  }
  .scoll-box {
    flex: 1;
  }
}
</style>