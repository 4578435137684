<template>
  <dv-border-box-10 :color="['rgba(46, 96, 153, 1)', 'rgba(124, 231, 253, 1)']">
    <dv-charts :option="option" />
  </dv-border-box-10>
</template>

<script>
import dayjs from 'dayjs'
const colors = {
  0: '#5470c6',
  1: '#91cc75',
  2: '#fac858',
  3: '#ee6666',
  4: '#73c0de',
  5: '#3ba272',
  6: '#fc8452',
  7: '#9a60b4',
  8: '#ea7ccc',
  9: '#4992ff',
  10: '#7cffb2',
  11: '#fddd60',
  12: '#ff6e76',
  13: '#58d9f9',
  14: '#05c091',
  15: '#ff8a45',
  16: '#8d48e3',
  17: '#dd79ff',
  18: '#f6efa6',
  19: '#d88273',
  20: '#bf444c'
}
export default {
  name: 'leftBottom',
  props: {
    originData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    option: function() {

      let legendData = []
      let seriesData = []
      if (this.originData.k1 && Array.isArray(this.originData.k1)) {
        legendData = this.originData.k1.map((item, index) => ({
          name: item.v1,
          color: colors[index] || this.createHexColor()
        }))
        // legendData = this.originData.k1.map((item) => item.v1)

        seriesData = this.originData.k1.map((item, index) => ({
          name: item.v1,
          data: Object.values(this.originData).map(arr => arr[index].v2),
          type: 'line',
          smooth: true,
          lineStyle: {
            stroke: colors[index]
          }
        }))
        // console.log(legendData, seriesData)

      }
      
      
      return {
        title: {
          text: '周投产数量',
          style: {
            fill: '#fff',
            fontSize: 19
          }
        },
        grid: {
          // bottom: 40,
          right: '28%'
        },
        legend: {
          orient: 'vertical',
          iconHeight: 8,
          textStyle: {
            fontSize: 12,
            fill: '#fff'
          },
          // top: '15%',
          data: legendData
          // data: [
          //   {
          //     name: 'Y-DS1001',
          //     color: '#5470c6',
          //   },
          //   {
          //     name: 'Y-DS1002',
          //     color: '#91cc75',
          //   },
          //   {
          //     name: 'Y-DS1003',
          //     color: '#fac858',
          //   },
          //   {
          //     name: 'Y-DS1004',
          //     color: '#ee6666',
          //   },
          //   {
          //     name: 'Y-DS1005',
          //     color: '#73c0de',
          //   },
          //   {
          //     name: 'Y-DS1006',
          //     color: '#3ba272',
          //   },
          //   {
          //     name: 'Y-DS1007',
          //     color: '#fc8452',
          //   },
          //   {
          //     name: 'Y-DS1008',
          //     color: '#9a60b4',
          //   },
          //   {
          //     name: 'Y-DS1009',
          //     color: '#ea7ccc',
          //   }
          // ]
        },
        xAxis: {
          nameTextStyle: {
            fill: '#fff',
            fontSize: 14
          },
          axisLine: {
            style: {
              stroke: '#fff'
            }
          },
          axisTick: {
            style: {
              stroke: '#fff'
            }
          },
          axisLabel: {
            style: {
              fill: '#fff',
              fontSize: 14
            }
          },
          name: '日期',
          data: [
            dayjs().subtract(6, 'day').format('M月D日'), 
            dayjs().subtract(5, 'day').format('M月D日'), 
            dayjs().subtract(4, 'day').format('M月D日'), 
            dayjs().subtract(3, 'day').format('M月D日'), 
            dayjs().subtract(2, 'day').format('M月D日'), 
            dayjs().subtract(1, 'day').format('M月D日'), 
            dayjs().format('M月D日')
          ]
        },
        yAxis: {
          min: 0,
          max: '1%',
          nameTextStyle: {
            fill: '#fff',
            fontSize: 14
          },
          axisLine: {
            style: {
              stroke: '#fff'
            }
          },
          axisTick: {
            style: {
              stroke: '#fff'
            }
          },
          axisLabel: {
            style: {
              fill: '#fff',
              fontSize: 14
            }
          },
          splitLine: {
            style: {
              stroke: 'rgba(46, 96, 153, 0.3)'
            }
          },
          name: '数量',
          data: 'value'
        },
        series: seriesData
        // series: [
        //   {
        //     name: 'Y-DS1001',
        //     data: [34, 20, 0, 0, 0, 0, 0],
        //     type: 'line',
        //     smooth: true,
        //   },
        //   {
        //     name: 'Y-DS1002',
        //     data: [40, 39, 0, 0, 0, 0, 0],
        //     type: 'line',
        //     smooth: true,
        //   },
        //   {
        //     name: 'Y-DS1003',
        //     data: [52, 20, 47, 52, 0, 0, 0],
        //     type: 'line',
        //     smooth: true,
        //   },
        //   {
        //     name: 'Y-DS1004',
        //     data: [0, 0, 40, 0, 40, 0, 100],
        //     type: 'line',
        //     smooth: true,
        //   },
        //   {
        //     name: 'Y-DS1005',
        //     data: [52, 20, 47, 52, 0, 0, 0],
        //     type: 'line',
        //     smooth: true,
        //   },
        //   {
        //     name: 'Y-DS1006',
        //     data: [0, 0, 54, 0, 30, 60, 0],
        //     type: 'line',
        //     smooth: true,
        //   },
        //   {
        //     name: 'Y-DS1007',
        //     data: [52, 20, 47, 52, 0, 0, 0],
        //     type: 'line',
        //     smooth: true,
        //   },
        //   {
        //     name: 'Y-DS1008',
        //     data: [52, 20, 47, 52, 0, 0, 0],
        //     type: 'line',
        //     smooth: true,
        //   },
        //   {
        //     name: 'Y-DS1009',
        //     data: [52, 20, 47, 52, 0, 0, 0],
        //     type: 'line',
        //     smooth: true,
        //   }
        // ]
      }
    }
  },
  methods: {
    createHexColor() {
      let values = {
        0: '0', 1: '1', 2: '2', 3: '3', 
        4: '4', 5: '5', 6: '6', 7: '7',
        8: '8', 9: '9', 10: 'A', 11: 'B', 
        12: 'C', 13: 'D', 14: 'E', 15: 'F',
      }
      let result = '#' + values[Math.floor(Math.random() * 16)]
      + values[Math.floor(Math.random() * 16)]
      + values[Math.floor(Math.random() * 16)]
      + values[Math.floor(Math.random() * 16)]
      + values[Math.floor(Math.random() * 16)]
      + values[Math.floor(Math.random() * 16)]
      return result
    }
  }
}
</script>

<style scoped>

</style>