<template>
  <div id="top-header">
    <dv-decoration-8 class="header-left-decoration" :color="['rgba(124, 231, 253, 1)', 'rgba(124, 231, 253, 1)']" />
    <dv-decoration-5 class="header-center-decoration" :color="['rgba(124, 231, 253, 1)', 'rgba(124, 231, 253, 1)']" />
    <dv-decoration-8 class="header-right-decoration" :reverse="true" :color="['rgba(124, 231, 253, 1)', 'rgba(124, 231, 253, 1)']" />
    <div class="center-title">
      产能数据大屏
      {{env === 'dev' ? '(开发版)' : ''}}
      {{env === 'test' ? '(测试版)' : ''}}
    </div>
  </div>
</template>

<script>
import config from "@/utils/config";
export default {
  name: 'TopHeader',
  data() {
    return {
      env: config.env
    }
  },
}
</script>

<style lang="scss">
#top-header {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  .header-center-decoration {
    width: 40%;
    height: 60px;
    margin-top: 24px;
  }
  .header-left-decoration, .header-right-decoration {
    width: 25%;
    height: 60px;
  }
  .center-title {
    position: absolute;
    font-size: 30px;
    letter-spacing: 2px;
    color: #fff;
    left: 50%;
    top: 24px;
    transform: translateX(-50%);
  }
}
</style>