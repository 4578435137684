<template>
  <div id="data-view">
    <dv-loading v-if="loading">加载中...</dv-loading>
    <dv-full-screen-container v-else class="container">
      <top-header />
      <div class="main-content">
        <div class="block-left">
          <div class="block-left-item block-left-item1">
            <left-top :origin-data="listOfInvestType" />
          </div>
          <div class="block-left-item block-left-item2">
            <left-middle :origin-data="listOfTodayInvest" />
          </div>
          <div class="block-left-item block-left-item3">
            <left-bottom :origin-data="listOfWeekInvest" />
          </div>
        </div>
        <div class="block-center">
          <div class="block-center-item block-center-item1">
            <center-top :num1="countOfTotalWeekInvest" :num2="countOfTotalWeekDelivery" />
          </div>
          <div class="block-center-item block-center-item2">
            <center-bottom />
          </div>
        </div>
        <div class="block-right">
          <div class="block-right-item block-right-item1">
            <right-top :origin-data="listOfTodayDelivery" />
          </div>
          <div class="block-right-item block-right-item2">
            <right-middle :origin-data="listOfWeekDelivery" />
          </div>
          <div class="block-right-item block-right-item3">
            <right-bottom :origin-data="companyList" />
          </div>
        </div>
      </div>
      <bottom-footer />
    </dv-full-screen-container>
  </div>
</template>

<script>
import topHeader from './topHeader'
import leftTop from './leftTop'
import leftMiddle from './leftMiddle'
import leftBottom from './leftBottom'
import centerTop from './centerTop'
import rightTop from './rightTop'
import rightMiddle from './rightMiddle'
import rightBottom from './rightBottom'
import centerBottom from './centerBottom'
import bottomFooter from './bottomFooter'
import { ajaxDatav } from '../utils/request'
export default {
  name: 'DataView',
  components: {
    topHeader,
    leftTop,
    leftMiddle,
    leftBottom,
    centerTop,
    rightTop,
    rightMiddle,
    rightBottom,
    centerBottom,
    bottomFooter
  },
  data() {
    return {
      loading: false,
      listOfTodayInvest: [],
      listOfTodayDelivery: [],
      listOfWeekDelivery: [],
      listOfWeekInvest: {},
      countOfTotalWeekInvest: '',
      countOfTotalWeekDelivery: '',
      listOfInvestType: [],
      companyList: [],
      timer: null,
    }
  },
  created() {
    // 测试
    // setTimeout(() => {
    //   console.log('msg')
    //   this.$msg('Hello')
    // }, 2000)

    // 获取数据
    this.getData('init')
    this.timer = setInterval(() => {
      this.getData()
    }, 30000)
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    getData(flag) {
      if (flag === 'init') this.loading = true
      Promise.allSettled([
        this.getListOfTodayInvest(),
        this.getListOfTodayDelivery(),
        this.getListOfWeekInvest(),
        this.getListOfWeekDelivery(),
        this.getCountOfTotalWeekDelivery(),
        this.getCountOfTotalWeekInvest(),
        this.getListOfInvestType(),
        this.getCompanyList(),
      ]).catch(e => {
        console.error(e)
      }).finally(() => {
        if (flag === 'init') this.loading = false
      })
    },
    // 今日投产数量
    getListOfTodayInvest() {
      return ajaxDatav.get('/capacity-dashboard-data/listOfTodayInvest').then(res => {
        if (res.code === 200) {
          this.listOfTodayInvest = res.data || []
        }
      }).catch(e => {
        console.error(e)
      })
    },
    // 今日交货数量
    getListOfTodayDelivery() {
      return ajaxDatav.get('/capacity-dashboard-data/listOfTodayDelivery').then(res => {
        if (res.code === 200) {
          this.listOfTodayDelivery = res.data || []
        }
      }).catch(e => {
        console.error(e)
      })
    },
    // 周投产数量
    getListOfWeekInvest() {
      return ajaxDatav.get('/capacity-dashboard-data/listOfWeekInvest').then(res => {
        if (res.code === 200) {
          this.listOfWeekInvest = res.data || {}
        }
      }).catch(e => {
        console.error(e)
      })
    },
    // 周交货数量
    getListOfWeekDelivery() {
      return ajaxDatav.get('/capacity-dashboard-data/listOfWeekDelivery').then(res => {
        if (res.code === 200) {
          this.listOfWeekDelivery = res.data || {}
        }
      }).catch(e => {
        console.error(e)
      })
    },
    // 周投产总数
    getCountOfTotalWeekInvest() {
      return ajaxDatav.get('/capacity-dashboard-data/countOfTotalWeekInvest').then(res => {
        if (res.code === 200) {
          this.countOfTotalWeekInvest = res.data || ''
        }
      }).catch(e => {
        console.error(e)
      })
    },
    // 周交货总数
    getCountOfTotalWeekDelivery() {
      return ajaxDatav.get('/capacity-dashboard-data/countOfTotalWeekDelivery').then(res => {
        if (res.code === 200) {
          this.countOfTotalWeekDelivery = res.data || ''
        }
      }).catch(e => {
        console.error(e)
      })
    },
    // 周投产品类分布
    getListOfInvestType() {
      return ajaxDatav.get('/capacity-dashboard-data/listOfInvestType').then(res => {
        if (res.code === 200) {
          this.listOfInvestType = res.data || []
        }
      }).catch(e => {
        console.error(e)
      })
    },
    // 企业名单
    getCompanyList() {
      return ajaxDatav.get('/capacity-dashboard-company/list').then(res => {
        if (res.code === 200) {
          this.companyList = res.data || []
        }
      }).catch(e => {
        console.error(e)
      })
    },
    
    
    
  }
}
</script>

<style lang="scss" scoped>
#data-view {
  width: 100%;
  height: 100%;
}
#dv-full-screen-container {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform-origin: left top;
  z-index: 999;
}
.container {
  background-color: #000066;
  background-image: url("../assets/imgs/pageBg.jpeg");
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.main-content {
  display: flex;
  height: calc(100% - 134px);

  // 左侧
  .block-left {
    height: 100%;
    width: 33.3%;
    display: flex;
    flex-direction: column;
  }
  .block-left-item {
    height: 33.3%;
    box-sizing: border-box;
    padding: 0 0 10px 10px;
    &:last-child {
      padding-bottom: 0;
    }
  }

  // 中间
  .block-center {
    height: 100%;
    width: 33.3%;
    display: flex;
    flex-direction: column;
  }
  .block-center-item {
    padding: 0 10px 10px;
    box-sizing: border-box;
    &.block-center-item1 {
      height: 28%;
    }
    &.block-center-item2 {
      height: 72%;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }

  // 右侧
  .block-right {
    height: 100%;
    width: 33.3%;
    display: flex;
    flex-direction: column;
  }
  .block-right-item {
    height: 33.3%;
    box-sizing: border-box;
    padding: 0 10px 10px 0;
    &:last-child {
      padding-bottom: 0;
    }
  }

}
</style>