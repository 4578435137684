import axios from "axios";
import config from "./config";

var baseUrl_local = "http://10.10.1.118:9010"; // 本地
var baseUrl_dev = 'https://api.dev.ysintelligent.com/data_statistics_service';  //dev
var baseUrl_test = "https://api.test.ysintelligent.com/data_statistics_service"; //test
var baseUrl = "https://api.ysintelligent.com/data_statistics_service";

var uploadUrl_local = "http://10.10.1.118:9010"; // 本地
var uploadUrl_dev = "https://api.dev.ysintelligent.com/file_service"; //dev
var uploadUrl_test = "https://api.test.ysintelligent.com/file_service"; //test
var uploadUrl = "https://api.ysintelligent.com/file_service";

var imgPrev_dev = "https://static.dev.ysintelligent.com";
var imgPrev_test = "https://static.test.ysintelligent.com";
var imgPrev = "https://static.ysintelligent.com";

//根据环境 改变接口地址
const environmental = config.env;
var resultApi = {};
if (environmental == "local") {
  resultApi = {
    //打包时修改成这个,把下面的注释
    baseUrl: baseUrl_local,
    uploadUrl: uploadUrl_local,
    imgPrev: imgPrev_dev,
  };
}
if (environmental == "dev") {
  resultApi = {
    baseUrl: baseUrl_dev, // baseUrl_dev,
    uploadUrl: uploadUrl_dev,
    imgPrev: imgPrev_dev,
  };
}
if (environmental == "test") {
  resultApi = {
    baseUrl: baseUrl_test,
    uploadUrl: uploadUrl_test,
    imgPrev: imgPrev_test,
  };
}
if (environmental == "prod") {
  resultApi = {
    baseUrl: baseUrl,
    uploadUrl: uploadUrl,
    imgPrev: imgPrev,
  };
}
export const api = resultApi;
var instance = axios.create({
  baseURL: api.baseUrl,
  timeout: 200000,
});

export const ajaxDatav = {
  post: function (url, data, headers) {
    let p = new Promise(function (resolve, reject) {
      let params = {
        method: "post",
        url: url,
      };
      if (data) params.data = data;
      if (headers) params.headers = headers;
      instance(params)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (response) {
          reject(response.data);
        });
    });
    return p;
  },
  get: function (url, data) {
    let args = data;
    let p = new Promise(function (resolve, reject) {
      instance({
        method: "get",
        url: url,
        params: args,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (response) {
          reject(response.data);
        });
    });
    return p;
  },
};
