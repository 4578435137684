<template>
  <dv-border-box-10 :color="['rgba(46, 96, 153, 1)', 'rgba(124, 231, 253, 1)']">
    <dv-charts :option="option" />
  </dv-border-box-10>
</template>

<script>
import category from '../utils/category'
export default {
  name: 'leftTop',
  props: {
    originData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    option: function() {
      let seriesData = [], radarIndicator = [], indicatorMax = 800, originDataObj = {}
      if (this.originData && this.originData.length > 0) {
        this.originData.forEach(item => {
          originDataObj[item.v1] = +item.v2 || 0
        })
      }
      seriesData = category.map(item => (originDataObj[item.name] || 0))
      indicatorMax = Math.max.apply(null, seriesData) + 200
      radarIndicator = category.map(item => ({
        name: item.name,
        max: indicatorMax
      }))
      return {
        title: {
          text: '7天内投产品类分布',
          // offset: [0, -20],
          style: {
            fill: '#fff',
            fontSize: 19,
          }
        },
        radar: {
          center: ['50%', '56%'],
          radius: '50%',
          polygon: true,
          indicator: radarIndicator,
          // indicator: [
          //   { name: '卫衣', max: 800 },
          //   { name: '连衣裙', max: 800 },
          //   { name: '外套', max: 800 },
          //   { name: '卫衣', max: 800 },
          //   { name: '单裙', max: 800 },
          //   { name: '裤装', max: 800 },
          //   { name: '连体服', max: 800 },
          //   { name: 'T恤', max: 800 }
          // ],
          axisLabel: {
            labelGap: 10,
            style: {
              fill: '#fff',
              fontSize: 14
            }
          },
          axisLine: {
            style: {
              stroke: 'rgba(46, 96, 153, 1)',
              lineWidth: 1.4
            }
          },
          splitLine: {
            style: {
              stroke: 'rgba(46, 96, 153, 1)',
              lineWidth: 1.4
            }
          },
          yAxis: {
            show: true,
            axisLabel: {
              show: true
            }
          }
        },
        series: [
          {
            type: 'radar',
            data: seriesData, // [676, 766, 160, 0, 0, 0, 0, 0],
            animationCurve: 'easeOutBack',
            label: {
              style: {
                stroke: 'rgba(124, 231, 253, 1)',
                fontSize: 12,
                lineWidth: 0.2
              }
            },
            radarStyle: {
              stroke: 'rgba(124, 231, 253, 1)',
              fill: 'rgba(124, 231, 253, 0.4)'
            }
          }
        ]
      }
       
      // else {
      //   return {
      //     title: {
      //       text: '7天内投产品类分布',
      //       style: {
      //         fill: '#fff'
      //       }
      //     }
      //   }
      // }
    }
  }
}
</script>

<style>

</style>