<template>
  <dv-border-box-10 :color="['rgba(46, 96, 153, 1)', 'rgba(124, 231, 253, 1)']">
    <div class="number-box">
      <div class="item">
        <dv-digital-flop :config="config1" style="height: 110px;" />
        <div class="text">一周内总投产数量</div>
      </div>
      <div class="item">
        <dv-digital-flop :config="config2" style="height: 110px;" />
        <div class="text">一周内总交货数量</div>
      </div>
    </div>
    
  </dv-border-box-10>
</template>

<script>
export default {
  name: 'centerTop',
  props: {
    num1: {
      type: [Number, String],
      default: ''
    },
    num2: {
      type: [Number, String],
      default: ''
    }
  },
  computed: {
    config1: function() {
      return {
        number: [this.num1],
        style: {
          fontSize: 72,
          fill: '#3de7c9',
        }
      }
    },
    config2: function() {
      return {
        number: [this.num2],
        style: {
          fontSize: 72,
          fill: '#3de7c9',
        }
      }
    }
  },
  data() {
    return {
      // config: {
      //   number: [100],
      //   style: {
      //     fontSize: 82,
      //     fill: '#3de7c9',
      //   }
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.number-box {
  height: 100%;
  display: flex;
  .item {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .text {
      color: #eee;
    }
  }
}
</style>