<template>
  <dv-border-box-11 title="生产过程" :color="['rgba(124, 231, 253, 1)', 'rgba(46, 96, 153, 1)']">
    <div style="padding: 80px 0 10px; height: 100%;">
      <dv-scroll-board :config="config"/>
    </div>
    
  </dv-border-box-11>
</template>

<script>
import img1 from '../assets/imgs/product/1.jpg'
import img2 from '../assets/imgs/product/2.jpg'
import img3 from '../assets/imgs/product/3.jpg'
import img4 from '../assets/imgs/product/4.jpg'
export default {
  name: 'centerBottom',
  data() {
    return {
      config: {
        data: [
          [
            `<img src="${img1}" style="height: 92%; width: 90%; object-fit: cover; border-radius: 8px" />`
          ],
          [
            `<img src="${img2}" style="height: 92%; width: 90%; object-fit: cover; border-radius: 8px" />`
          ],
          [
            `<img src="${img3}" style="height: 92%; width: 90%; object-fit: cover; border-radius: 8px" />`
          ],
          [
            `<img src="${img4}" style="height: 92%; width: 90%; object-fit: cover; border-radius: 8px" />`
          ],
        ],
        oddRowBGC: 'rgba(46, 96, 153, 0)',
        evenRowBGC: 'rgba(3, 169, 244, 0)',
        align: ['center'],
        rowNum: 2,
      }
    }
  }
}
</script>

<style>

</style>